<template>
  <div v-if="item.divider">
    <hr class="border-slate-500" />
  </div>
  <div
    v-else
    class="cursor-pointer border-b border-slate-300 text-slate-600 hover:bg-slate-100"
  >
    <div
      class="flex items-center px-4 py-2"
      :class="{
        'border-r-4 bg-slate-100':
          item.path === '/'
            ? route.path === item.path
            : route.path.startsWith(item.path),
      }"
      :style="{ borderColor: company.primary[500] }"
    >
      <component class="h-5 w-5" :is="item.icon" />
      <span class="ml-3 font-semibold">{{
        t(`navigation.${item.label}`)
      }}</span>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
export default {
  props: {
    company: {
      required: true,
    },
    item: {
      required: true,
      type: Object,
    },
  },
  setup() {
    const route = useRoute();
    const { t } = useI18n();

    const hover = ref(false);

    return {
      hover,
      route,
      t,
    };
  },
};
</script>
