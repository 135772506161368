<template>
  <div class="flex w-full flex-col border-t bg-white">
    <navigation-item
      v-for="item in filteredItems"
      :company="company"
      :key="item.index"
      :item="item"
      @click="navigateTo(item)"
    />
  </div>
</template>

<script>
import {
  BellIcon,
  CurrencyEuroIcon,
  DocumentIcon,
  FolderIcon,
  HomeIcon,
  MegaphoneIcon,
  ShoppingBagIcon,
} from "@heroicons/vue/24/outline";
import { computed, reactive } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { isBoardMember, isShareholder } from "../../utils/roleUtils";
import NavigationItem from "./NavigationItem.vue";
export default {
  components: { NavigationItem },
  props: {
    company: {
      required: true,
    },
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    const user = computed(() => store.state.authentication.user);

    const items = reactive([
      { icon: HomeIcon, label: "home", path: "/" },
      {
        icon: BellIcon,
        label: "notifications",
        path: "/notifications",
      },
      {
        icon: MegaphoneIcon,
        label: "bulletins",
        path: "/bulletins",
      },
      {
        icon: DocumentIcon,
        label: "documents",
        path: "/documents",
        meta: {
          shareholderRequired: true,
        },
      },
      {
        icon: FolderIcon,
        label: "files",
        path: "/files",
        meta: {
          shareholderRequired: true,
        },
      },
      /*{
        icon: ShoppingBagIcon,
        label: "shop",
        path: "/shop",
        meta: {
          shareholderRequired: true,
        },
      },
      {
        icon: CurrencyEuroIcon,
        label: "orders",
        path: "/orders",
        meta: {
          shareholderRequired: true,
        },
      },*/
    ]);

    const filteredItems = computed(() => {
      return items.filter((item) => {
        if (
          item.meta?.shareholderRequired &&
          !isBoardMember(user.value) &&
          !isShareholder(user.value)
        ) {
          return false;
        } else if (
          item.meta?.boardMemberRequired &&
          !isBoardMember(user.value)
        ) {
          return false;
        }
        return true;
      });
    });

    const navigateTo = (item) => {
      router.push(item.path);
    };

    return {
      filteredItems,
      navigateTo,
    };
  },
};
</script>
